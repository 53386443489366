<template>
    <div class="bk-contact-area section-pb-xl bg_color--1">
        <div class="container pt_sm--10 pt--50">
            <div class="row">
                <!-- Start Address -->
                <div class="col-xl-3 col-lg-3 col-12">
                    <div class="address-inner">
                        <div class="address wow move-up">
                            <h3 class="heading">Visit our offices at</h3>
                            <p>5001 Lavista Rd, Tucker, GA 30084</p>
                        </div>

                        <div class="address mt--60 mb--60 wow move-up">
                            <h3 class="heading">Email Us</h3>
                            <p><a href="mailto:create1element@gmail.com">bob@celement.com</a></p>
                            <p><a href="tel:(+1)7709081075">(770) 908-1075</a></p>
                        </div>

<!--                        <ul class="social-icon icon-size-medium text-dark text-left tooltip-layout move-up wow">-->
<!--                            <li class="facebook">-->
<!--                                <a href="https://www.facebook.com/" class="link hint&#45;&#45;bounce hint&#45;&#45;top hint&#45;&#45;theme" aria-label="Facebook">-->
<!--                                    <i class="fab fa-facebook"></i>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li class="twitter">-->
<!--                                <a href="https://twitter.com/" class="link hint&#45;&#45;bounce hint&#45;&#45;top hint&#45;&#45;theme" aria-label="Twitter">-->
<!--                                    <i class="fab fa-twitter"></i>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li class="instagram">-->
<!--                                <a href="https://www.instagram.com/" class="link hint&#45;&#45;bounce hint&#45;&#45;top hint&#45;&#45;theme" aria-label="Instagram">-->
<!--                                    <i class="fab fa-instagram"></i>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li class="dribbble">-->
<!--                                <a href="https://dribbble.com/" class="link hint&#45;&#45;bounce hint&#45;&#45;top hint&#45;&#45;theme" aria-label="Dribbble">-->
<!--                                    <i class="fab fa-dribbble"></i>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li class="pinterest">-->
<!--                                <a href="https://www.pinterest.com/" class="link hint&#45;&#45;bounce hint&#45;&#45;top hint&#45;&#45;theme" aria-label="Pinterest">-->
<!--                                    <i class="fab fa-pinterest"></i>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                        </ul>-->

                    </div>
                </div>
                <!-- Start Contact Form -->
                <div class="col-xl-8 offset-xl-1 col-lg-9 col-12 mt_md--40 mt_sm--40">
                    <div v-if="errorMessage && errorMessage.length > 0" class="message-box move-up wow py-3 bg_cat--2 mb-3">
                        <div class="icon" style="top: 20px;">
                            <i class="fas fa-exclamation-triangle"></i>
                        </div>
                        <div class="content">{{ errorMessage }}</div>
                    </div>
                    <div v-if="success" class="message-box move-up wow py-3 bg_cat--3 mb-3">
                        <div class="icon" style="top: 20px;">
                            <i class="fas fa-check-circle"></i>
                        </div>
                        <div class="content">Thank you for Reaching out.  We will contact you shortly.</div>
                    </div>
                    <div v-if="!success" class="contact-form">
                        <form class="form-style--1 wow move-up" @submit="submitForm($event)">
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="input-box">
                                        <span class="form-icon far fa-user"></span>
                                        <input v-model="contactForm.name" type="text" placeholder="Name *">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="input-box">
                                        <span class="form-icon far fa-envelope"></span>
                                        <input v-model="contactForm.email" type="text" placeholder="Email *">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="input-box">
                                        <span class="form-icon fas fa-mobile-alt"></span>
                                        <input v-model="contactForm.phone" type="text" placeholder="Phone number *">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="input-box">
                                        <textarea v-model="contactForm.message" placeholder="Your Message *"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-action">
                                        <div class="form-description">
                                          <recaptcha />
                                        </div>
                                        <div class="form-submit">
                                            <input type="submit" value="Send message">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import contactForm from "@/components/ContactForm.vue";
    import MessageBox from "@/pages/element/message-box.vue";

    const emptyContactForm = {
      name: '',
      email: '',
      phone: '',
      message: '',
      token: undefined,
    };

    // email template id: d-e7468051caa640f0bfca7b3d5e0a9f20

    // email api key: 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

    //recaptcha: 6LeNUWElAAAAAN0MPdQU6Wuu7_nXe_7R2pXnLRLb

    //recaptcha server: 6LeNUWElAAAAAGsHSKUYU_pflPvDiyERA5dPSRnY

    export default {
      components: {MessageBox},
      data: () => ({
        contactForm: Object.assign({}, emptyContactForm),
        errorMessage: null,
        success: false,
      }),
      methods: {
        reset() {
          this.contactForm = Object.assign({}, emptyContactForm);
          this.$recaptcha.reset();
        },
        async submitForm(event) {
          event.preventDefault();
          for (let field of Object.getOwnPropertyNames(this.contactForm)) {
            if (this.contactForm[field] && this.contactForm[field].length === 0) {
              this.errorMessage = `${field.slice(0, 1).toUpperCase()}${field.slice(1)} is required.`;
              return;
            }
          }
          try {
            this.contactForm.token = await this.$recaptcha.getResponse();
          } catch (e) {
            this.errorMessage = 'Please Solve the Captcha'
            return;
          }
          try {
            await fetch('https://celement.com/api/', {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(Object.assign({}, this.contactForm)),
            });
          } catch (e) {
            this.errorMessage('We\'re sorry.  We were unable to process your request at this time.');
            return;
          }
          this.reset();
          this.success = true;
        }
      },
      beforeDestroy() {
        this.$recaptcha.destroy();
      }
    };
</script>

<style lang="scss">

</style>
